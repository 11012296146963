import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const NavigationButtons = ({
  prevSentence,
  nextSentence,
  currentChapter,
  currentSentence,
  storiesLength,
  currentStoryLength,
  transitionEffect,
  theme,
}) => (
  <div className="flex justify-between items-center w-full max-w-3xl mb-6">
    <button
      onClick={prevSentence}
      disabled={currentChapter === 0 && currentSentence === 0}
      className={`${theme.button} text-white p-4 rounded-full disabled:opacity-50 transition-all duration-300 ${
        transitionEffect === 'left' ? theme.buttonActive + ' scale-110' : ''
      }`}
    >
      <ChevronLeft size={32} />
    </button>

    <button
      onClick={nextSentence}
      disabled={currentChapter === storiesLength - 1 && currentSentence === currentStoryLength - 1}
      className={`${theme.button} text-white p-4 rounded-full disabled:opacity-50 transition-all duration-300 ${
        transitionEffect === 'right' ? theme.buttonActive + ' scale-110' : ''
      }`}
    >
      <ChevronRight size={32} />
    </button>
  </div>
);

export default NavigationButtons;