import React from 'react';

const ProgressBars = ({ chapterProgress, storyProgress, theme }) => (
  <div className="w-full max-w-3xl mb-6">
    <div className="mb-2">
      <p className="text-sm text-indigo-600 mb-1">Chapter Progress</p>
      <div className={`w-full ${theme.progressBg} rounded-full h-2`}>
        <div 
          className={`${theme.progress} h-2 rounded-full transition-all duration-300 ease-in-out`} 
          style={{ width: `${chapterProgress}%` }}
        ></div>
      </div>
    </div>
    <div>
      <p className="text-sm text-indigo-600 mb-1">Overall Story Progress</p>
      <div className={`w-full ${theme.progressBg} rounded-full h-2`}>
        <div 
          className={`${theme.progress} h-2 rounded-full transition-all duration-300 ease-in-out`} 
          style={{ width: `${storyProgress}%` }}
        ></div>
      </div>
    </div>
  </div>
);

export default ProgressBars;