export const themes = {
  default: {
    background: 'bg-gradient-to-b from-indigo-50 to-blue-100',
    header: 'bg-indigo-600',
    text: 'text-gray-800',
    button: 'bg-indigo-500 hover:bg-indigo-600',
    buttonActive: 'bg-indigo-700',
    progress: 'bg-indigo-400',
    progressBg: 'bg-indigo-100',
    highlight: 'bg-indigo-100 text-indigo-700',
    highlightHover: 'hover:bg-indigo-50',
  },
  pink: {
    background: 'bg-gradient-to-b from-pink-50 to-pink-100',
    header: 'bg-pink-600',
    text: 'text-gray-800',
    button: 'bg-pink-500 hover:bg-pink-600',
    buttonActive: 'bg-pink-700',
    progress: 'bg-pink-400',
    progressBg: 'bg-pink-100',
    highlight: 'bg-pink-100 text-pink-700',
    highlightHover: 'hover:bg-pink-50',
  },
  blue: {
    background: 'bg-gradient-to-b from-blue-50 to-blue-100',
    header: 'bg-blue-600',
    text: 'text-gray-800',
    button: 'bg-blue-500 hover:bg-blue-600',
    buttonActive: 'bg-blue-700',
    progress: 'bg-blue-400',
    progressBg: 'bg-blue-100',
    highlight: 'bg-blue-100 text-blue-700',
    highlightHover: 'hover:bg-blue-50',
  },
  green: {
    background: 'bg-gradient-to-b from-green-50 to-green-100',
    header: 'bg-green-600',
    text: 'text-gray-800',
    button: 'bg-green-500 hover:bg-green-600',
    buttonActive: 'bg-green-700',
    progress: 'bg-green-400',
    progressBg: 'bg-green-100',
    highlight: 'bg-green-100 text-green-700',
    highlightHover: 'hover:bg-green-50',
  },
};

export const fontSizes = ['text-lg', 'text-xl', 'text-2xl', 'text-3xl', 'text-4xl'];

export const fontFamilies = [
  { name: 'Sans', value: 'font-sans' },
  { name: 'Serif', value: 'font-serif' },
  { name: 'Mono', value: 'font-mono' },
];