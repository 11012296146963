import React from 'react';

const Header = ({ logo, theme }) => (
  <header className={`${theme.header} text-white p-6 flex justify-between items-center shadow-lg`}>
    <div className="flex items-center">
      <img src={logo} alt="Logo" className="h-[60px] mr-4" />
      <h1 className="text-3xl font-bold">It's Time to Read</h1>
    </div>
  </header>
);

export default Header;