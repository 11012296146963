import React from 'react';
import { Type, Plus, Minus } from 'lucide-react';

const FontToolbar = ({
  toggleFontToolbar,
  showFontToolbar,
  fontToolbarPosition,
  decreaseFontSize,
  increaseFontSize,
  setFontFamily,
  fontFamily,
  fontFamilies,
}) => (
  <div className="relative">
    <button
      onClick={toggleFontToolbar}
      className="p-2 bg-white rounded-full shadow-md hover:bg-gray-100 z-20"
    >
      <Type size={20} />
    </button>
    {showFontToolbar && (
      <div 
        className="absolute bg-white rounded-lg shadow-md p-2 z-30"
        style={{ top: `${fontToolbarPosition.top}px`, left: `${fontToolbarPosition.left}px` }}
      >
        <div className="flex items-center space-x-2">
          <button onClick={decreaseFontSize} className="p-1 hover:bg-gray-100 rounded">
            <Minus size={20} />
          </button>
          <button onClick={increaseFontSize} className="p-1 hover:bg-gray-100 rounded">
            <Plus size={20} />
          </button>
          {fontFamilies.map((font) => (
            <button
              key={font.value}
              onClick={() => setFontFamily(font.value)}
              className={`p-1 hover:bg-gray-100 rounded ${fontFamily === font.value ? 'bg-blue-100' : ''}`}
            >
              <span className={`${font.value} text-sm`}>{font.name}</span>
            </button>
          ))}
        </div>
      </div>
    )}
  </div>
);

export default FontToolbar;