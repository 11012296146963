import React from 'react';
import { ChevronRight, Menu, ChevronDown } from 'lucide-react';

const LeftPanel = ({
  isCollapsed,
  setIsCollapsed,
  isCollectionDropdownOpen,
  setIsCollectionDropdownOpen,
  isDropdownOpen,
  setIsDropdownOpen,
  availableStories,
  currentStoryIndex,
  setCurrentStoryIndex,
  stories,
  currentChapter,
  goToChapter,
  availableCollections,
  currentCollectionIndex,
  setCurrentCollectionIndex,
  handleCollectionChange,
  handleStoryChange,
  theme,
}) => (
  <div className={`${isCollapsed ? 'bg-transparent' : 'bg-white'} rounded-2xl shadow-lg overflow-hidden transition-all duration-300 ease-in-out ${
    isCollapsed ? 'w-12 shadow-none' : 'w-full md:w-64'
  } flex flex-col`}>
    <button
      onClick={() => setIsCollapsed(!isCollapsed)}
      className={`p-3 text-blue-600 hover:text-blue-800 ${isCollapsed ? 'self-center' : 'self-end'}`}
    >
      {isCollapsed ? <ChevronRight size={28} /> : <Menu size={28} />}
    </button>
    
    {!isCollapsed && (
      <div className="p-4 overflow-y-auto flex-grow">
        {/* Collection Dropdown */}
        <div className="mb-6">
          <label htmlFor="collection-select" className="block text-lg font-semibold text-gray-700 mb-2">
            Collection
          </label>
          <div className="relative">
            <button
              onClick={() => setIsCollectionDropdownOpen(!isCollectionDropdownOpen)}
              className="w-full bg-gray-50 border border-gray-300 rounded-md py-2 px-3 text-left focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-lg flex justify-between items-center"
            >
              <span>{availableCollections[currentCollectionIndex]?.title || 'Select a collection'}</span>
              <ChevronDown size={20} className={`transition-transform duration-200 ${isCollectionDropdownOpen ? 'transform rotate-180' : ''}`} />
            </button>
            {isCollectionDropdownOpen && (
              <ul className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {availableCollections.map((collection, index) => (
                  <li
                    key={collection.id}
                    className={`cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-blue-100 ${
                      index === currentCollectionIndex ? 'bg-blue-50 text-blue-600' : 'text-gray-900'
                    }`}
                    onClick={() => {
                      setCurrentCollectionIndex(index);
                      handleCollectionChange(index);
                      setIsCollectionDropdownOpen(false);
                    }}
                  >
                    {collection.title}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        {/* Story Dropdown */}
        <div className="mb-6">
          <label htmlFor="story-select" className="block text-lg font-semibold text-gray-700 mb-2">
            Story
          </label>
          <div className="relative">
            <button
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="w-full bg-gray-50 border border-gray-300 rounded-md py-2 px-3 text-left focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-lg flex justify-between items-center"
            >
              <span>{availableStories[currentStoryIndex]?.title || 'Select a story'}</span>
              <ChevronDown size={20} className={`transition-transform duration-200 ${isDropdownOpen ? 'transform rotate-180' : ''}`} />
            </button>
            {isDropdownOpen && (
              <ul className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {availableStories.map((story, index) => (
                  <li
                    key={story.id}
                    className={`cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-blue-100 ${
                      index === currentStoryIndex ? 'bg-blue-50 text-blue-600' : 'text-gray-900'
                    }`}
                    onClick={() => {
                      console.log('Story selected:', story);
                      setCurrentStoryIndex(index);
                      handleStoryChange(index);
                      setIsDropdownOpen(false);
                    }}
                  >
                    {story.title}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        
        {/* Table of Contents */}
        <h2 className={`text-2xl font-bold mb-4 ${theme.text}`}>Chapters</h2>
        <ul className="space-y-3">
          {stories.map((chapter, index) => (
            <li key={index}>
              <button
                onClick={() => goToChapter(index)}
                className={`text-left w-full py-2 px-3 rounded-lg transition-colors ${
                  index === currentChapter 
                    ? theme.highlight + ' font-bold' 
                    : 'text-gray-700 ' + theme.highlightHover
                }`}
              >
                {chapter.title}
              </button>
            </li>
          ))}
        </ul>
      </div>
    )}
  </div>
);

export default LeftPanel;